const Emergency = () => import('@/view/pages/emergency/List.vue');

export default [
  {
    path: '/emergency',
    name: 'emergency',
    component: Emergency,
    meta: {
      title: 'Khẩn cấp - DDV',
    },
  },
];
